<template>
  <div>
    <h3 class="modal__title">Change Password</h3>
    <loader v-if="loading" :container="true" />
    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <v-password
          v-model="old"
          name="Current password"
          placeholder="Enter your current password"
        >
          <h3 slot="label">Current Password</h3>
        </v-password>

        <validation-provider
          name="new pwd"
          :rules="{ is_not: old }"
          v-slot="{ errors }"
        >
          <v-password
            v-model="password"
            name="New password"
            placeholder="Create new password (8-60 characters)"
          >
            <h3 slot="label">New Password</h3>
            <div class="error-message mt" v-if="errors[0]" slot="error">
              New password should not be equal old password
            </div>
          </v-password>
        </validation-provider>

        <validation-provider
          name="confirmed"
          :rules="{ is: password }"
          v-slot="{ errors }"
        >
          <v-password
            v-model="confirm"
            name="Confirmed password"
            placeholder="Repeat your new password"
          >
            <h3 slot="label">Confirm Password</h3>
            <div class="error-message mt" v-if="errors[0]" slot="error">
              Confirmed password should be equal new password
            </div>
          </v-password>
        </validation-provider>

        <div v-if="error" v-html="error" class="text-danger" />

        <div class="modal__footer">
          <button
            type="button"
            class="button button_text button_md"
            @click="onClose"
          >
            cancel
          </button>
          <button type="submit" class="button button_secondary button_md">
            submit
          </button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vPassword from "../formElements/password";
import Loader from "@/components/loader";
import { formatErrorsToString } from "@/modules/utils";
import { saveLog } from '@/modules/logger';

import {
  STORE_CHANGE_PASSWORD,
  POPUP_NOTIFICATION,
  MSG_PASSWORD_UPDATED,
} from "@/constants";

export default {
  components: { vPassword, Loader },

  inject: {
    showModal: { default: () => {} },
  },

  data: () => ({
    old: "",
    password: "",
    confirm: "",
    error: null,
    loading: false,
  }),

  computed: {
    isEqual() {
      return this.password === this.confirm;
    },
  },

  methods: {
    ...mapActions([STORE_CHANGE_PASSWORD]),

    onSubmit() {
      saveLog("change-password-submit");
      this.loading = true;
      this.error = null;

      this[STORE_CHANGE_PASSWORD]({
        old_password: this.old,
        new_password: this.password,
      })
        .then(this.onSuccess)
        .catch(this.errorHandler)
        .finally(this.finalHandler);
    },

    finalHandler() {
      this.loading = false;
    },

    onClose() {
      saveLog("close-change-password");
      this.$emit("close");
    },

    onSuccess() {
      saveLog("change-password-success");
      this.onClose();
      this.showModal(POPUP_NOTIFICATION, {
        title: MSG_PASSWORD_UPDATED,
        icon: "check",
      });
    },

    errorHandler(error) {
      if (!error.response) return;
      saveLog("change-password-failure", {error: error.response.data});
      this.error = formatErrorsToString(error.response.data);
    },
  },
};
</script>
