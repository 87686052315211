<template>
  <div>
    <validation-provider
      :name="name"
      rules="required|min:8|max:60"
      v-slot="{ errors, touched, pristine }"
    >
      <div
        class="form__field-wrapper"
        :class="{ error: (touched || pristine) && errors[0] }"
      >
        <button
          type="button"
          class="button button_text button_md password_button"
          @click="onChange"
        >
          {{ type === 'password' ? 'Show' : 'Hide' }}
        </button>

        <slot name="label">
          <h3 class="form__field-label">Password</h3>
        </slot>

        <v-input
          v-model="password"
          :placeholder="placeholder"
          :type="type"
          class="form__field password_input"
          autocomplete="password"
        />

        <slot name="error">
          <div class="error-message form__field-error" v-if="errors[0]">
            {{ name }} should be between 8 and 60 characters
          </div>
        </slot>
      </div>
    </validation-provider>
  </div>
</template>

<script>
import vInput from '@/components/ui/input';
export default {
  components: { vInput },
  props: {
    value: { type: [String, Number], default: '' },
    placeholder: { type: String, default: '' },
    name: { type: String, default: 'Password' },
  },
  data() {
    return {
      type: 'password',
    };
  },
  computed: {
    password: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onChange() {
      console.log(123);
      this.type = this.type === 'password' ? 'text' : 'password';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/basic/variables';
@import '@/assets/styles/basic/mixins';
.password {
  &_button {
    position: absolute;
    bottom: 2px;
    right: 0;
    z-index: 100;
    @include media-mobile {
      top: calc(28px + 1rem);
      bottom: unset;
    }
  }
  &_input {
    padding-right: 5rem;
  }
}
</style>
